import { useLocation } from 'react-router-dom';
import { LinkedInBlog, FacebookBlog, TwitterBlog, Whatsapp, Copy } from '../../../assets/svgs';
import { Container } from './styles';

const ShareArticle = ({ post }) => {
	const { pathname } = useLocation();
	const { title, snippet } = post;

	const BASE_URL = 'https://descinder.com';
	const PAGE_URL = BASE_URL + pathname;

	const copyLink = () => {
		navigator.clipboard.writeText(PAGE_URL).then(
			(success) => alert('Link has been copied to your clipboard'),
			(err) => alert('Was unable to copy text to your clipboard')
		);
	};

	return (
		<Container>
			<h6>
				<span>Share</span> this article
			</h6>

			<div className="socials">
				<a
					href={`http://www.linkedin.com/shareArticle?mini=true&url=${BASE_URL}${pathname}&title=${title}&summary=${snippet}&source=descinder.com`}
				>
					<LinkedInBlog />
				</a>
				<a href={`https://www.facebook.com/sharer/sharer.php?u=${PAGE_URL}`} target="_blank" rel="noreferrer">
					<FacebookBlog />
				</a>
				<a href={`http://www.twitter.com/share?url=${PAGE_URL}`} target="_blank" rel="noreferrer">
					<TwitterBlog />
				</a>
				<a href={`whatsapp://send?text=${PAGE_URL}`} /*action="share/whatsapp/share"*/ target="_blank" rel="noreferrer">
					<Whatsapp />
				</a>
			</div>

			<div className="copy" onClick={copyLink}>
				<Copy /> Copy link
			</div>
		</Container>
	);
};

export default ShareArticle;
/*
LinkedIn
https://www.linkedin.com/shareArticle?mini=true&url={articleUrl}&title={articleTitle}&summary={articleSummary}&source={articleSource}
Doc
https://developer.linkedin.com/docs/share-on-linkedin
*/
