import { Container } from './styles';
import { Loader } from '../../ui';
import ShareArticle from './ShareArticle';
import { getDateTime } from '../../utilities';
import { useParams } from 'react-router-dom';
import { useGetBlogs } from '../../hooks';
import { PageNotFound, SomethingWentWrong } from '../../components';

const SingleInsight = () => {
	const { slug } = useParams();

	const { data: blogs, isLoading, isError } = useGetBlogs({ page: 1, page_size: 1000, is_published: true });

	const blog = blogs?.results?.find((blog) => blog?.slug === slug);

	return (
		<Container>
			{isLoading ? (
				<center>
					<Loader />
				</center>
			) : isError ? (
				<>
					<br />
					<br />
					<SomethingWentWrong />
				</>
			) : !blog?.id ? (
				<>
					<br />
					<br />
					<br />
					<PageNotFound />
				</>
			) : (
				<>
					<div className="blog-container">
						<header>
							<h1>{blog?.title}</h1>
							<p className="date-time">Last Updated {getDateTime(blog?.updated_at, 'mm dd yyyy')}</p>
							<h6>BY {blog?.author}</h6>
						</header>

						<main>
							<div className="hero-img-con">
								<img src={blog?.thumbnail} alt={blog?.title} />
							</div>

							<div className="divider">
								<section className="content" dangerouslySetInnerHTML={{ __html: blog?.body }} />

								<section>
									{/* <MiniNewsletter /> */}
									{blog?.id ? <ShareArticle post={blog} /> : null}
								</section>
							</div>
						</main>
					</div>
				</>
			)}
		</Container>
	);
};

export default SingleInsight;
