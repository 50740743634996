import { useNavigate } from 'react-router-dom';
import { Container } from './styles';
import { Approutes } from '../../../constants';
import { Button } from '../../../ui';

const PageNotFound = () => {
	const navigate = useNavigate();

	return (
		<Container>
			<main>
				<div>
					<h1>404</h1>
					<p>Can't seem to find the page you're looking for</p>
					<Button onClick={() => navigate(Approutes.home)}>Go Home</Button>
				</div>
			</main>
		</Container>
	);
};

export default PageNotFound;
