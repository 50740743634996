import { useQuery } from '@tanstack/react-query';
import { Apiurls } from '../constants/apiUrls';
import { axios } from '../library';

export const useGetBlogs = ({ page = 1, page_size = 5, search = '', category = '', is_published }) => {
	return useQuery({
		queryFn: () =>
			axios
				.get(
					`${Apiurls.blogs.get}?page=${page}&page_size=${page_size}&search=${search}&category=${category}${
						is_published ? `&is_published=${is_published}` : ''
					}`
				)
				.then((response) => response.data),
		queryKey: ['blogs', { page, page_size, search, category, is_published }],
	});
};
export const useGetBlog = ({ id }) => {
	return useQuery({
		queryFn: () => axios.get(Apiurls.blogs.single(id)).then((response) => response?.data),
		queryKey: ['blog', id],
	});
};
