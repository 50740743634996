export const Apiurls = {
	contact: '/contact',
	consult: '/consult',
	experience: '/gain-experience',
	startup: '/kickstart-idea',
	blogs: {
		get: '/blogs/',
		single: (id) => `/blogs/${id}/`,
	},
};
