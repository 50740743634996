import styled from 'styled-components';

export const Container = styled.div`
	padding: 24px 32px;
	background: #fffcf5;
	border: 1px solid #fff0cf;
	border-radius: 8px;

	h6 {
		font-size: 19px;
		line-height: 23px;
		span {
			color: #4060ea;
		}
	}

	.socials {
		display: flex;
		align-items: center;
		gap: 32px;
		margin-top: 24px;
		padding-bottom: 24px;
		border-bottom: 1px solid #e7eaee;
	}
	.copy {
		display: flex;
		align-items: center;
		gap: 8px;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		margin-top: 24px;
		cursor: pointer;
		width: fit-content;
	}
`;
