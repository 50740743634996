import styled from 'styled-components';
import { maxQuery } from '../../utilities';

export const Container = styled.section`
	margin-top: 3rem;
	padding: 6.25rem 8.5rem 7rem;
	padding: 6.25rem clamp(2rem, -1.75rem + 12vw, 8.75rem) 7rem;

	h2 {
		color: #141f39;
		text-align: center;
		font-family: 'Urbanist', sans-serif;
		font-size: 2.7985rem;
		font-size: clamp(2rem, 0.8022rem + 3.194vw, 2.7985rem);

		letter-spacing: -0.12675rem;
		text-transform: capitalize;
		line-height: 110%;
		position: relative;

		::before {
			content: '';
			width: 16%;
			max-width: 8.875rem;
			min-width: 2rem;
			height: 0.3125rem;
			background: #4060ea;
			position: absolute;
			top: 2.85rem;
			top: clamp(2rem, 0.8022rem + 3.194vw, 2.85rem);
			left: 50%;
			transform: translateX(-50%);
		}
	}
	h2 + p {
		color: #4f4f4f;
		text-align: center;

		font-size: 1.25rem;
		font-size: clamp(0.75rem, 0rem + 2vw, 1.25rem);

		line-height: 130%;
		margin-top: 1rem;

		${maxQuery('sm')} {
			margin-top: 0.5rem;
		}
	}

	.header {
		text-align: center;
		margin-bottom: 80px;
	}
	h1 {
		margin-bottom: 16px;

		@media (max-width: 950px) {
			margin-bottom: 11px;
		}
		@media (max-width: 520px) {
			margin-bottom: 6px;
		}
	}
	.header div {
		font-size: 18px;
		line-height: 36px;
		max-width: 750px;
		margin: auto;

		@media (max-width: 950px) {
			font-size: 17px;
			line-height: 30px;
		}
		@media (max-width: 520px) {
			font-size: 16px;
			line-height: 24px;
		}
	}

	.featured-posts-con {
		.controls {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.pagination {
			display: flex;
			gap: 0.5rem;
			margin-right: 1.5rem;

			> div {
				width: 15px;
				height: 4px;
				background: #141626;
				opacity: 0.2;
				border-radius: 4px;
				transition: all 0.5s;
				cursor: pointer;

				&.active {
					width: 25px;
					opacity: 1;
				}
			}
		}
		.arrows {
			display: flex;
			align-items: center;
			gap: 0.5rem;

			span {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 24px;
				height: 24px;
				background: #f7f8f9;
				border-radius: 50%;

				&.active {
					background: #d1f8ff;
					cursor: pointer;

					path {
						stroke: #001619;
					}
				}

				:first-child svg {
					transform: rotate(180deg) translateX(1px);
				}
			}
			svg {
				height: 18px;

				path {
					stroke: #b8c0cc;
				}
			}
		}
		.screen {
			overflow: hidden;
		}
		.featured-posts {
			display: flex;
			width: ${({ numOfFeaturedPost }) => numOfFeaturedPost * 100 + '%'};
			transition: transform 0.5s ease-in-out;
			/* transform: translateX(${({ translateFeatured }) => '-' + translateFeatured + 'px'}); */
		}
	}
	.posts-con {
		.category-con {
			color: #0d0f11;
			position: relative;

			select {
				text-transform: capitalize;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 8px 16px;
				gap: 8px;
				border: 1px solid #0d0f11;
				border-radius: 4px;
				background-color: #fff;
				color: inherit;
				font-weight: 600;
				font-size: 14px;
				line-height: 17px;
				min-width: 160px;
				background-position-y: calc(100% - 9px);
				background-image: url("data:image/svg+xml;utf8,<svg stroke='currentColor' fill='rgb(184, 192, 204)' stroke-width='0' viewBox='0 0 1024 1024' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'><path d='M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z'></path></svg>");
			}
		}
	}

	.heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 24px;

		h4 {
			font-size: 19px;
			line-height: 23px;

			@media (max-width: 400px) {
				font-size: 14px;
				line-height: 23px;
			}
		}
	}

	.posts {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		gap: 56px 20px;

		@media (max-width: 600px) {
			grid-template-columns: 1fr;
		}
	}
`;

export const Post = styled.div.attrs({ tabIndex: 0 })`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;

	.img-con {
		height: 250px;
		width: 100%;
	}
	.caption {
		font-weight: 700;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.08em;
		text-transform: uppercase;
		background-color: var(--blue-500);
		color: #fff;
		width: fit-content;
		padding: 4px;
		margin: 16px 0 12px;

		@media (max-width: 520px) {
			font-size: 10px;
		}
	}
	.title {
		font-weight: 600;
		font-size: 18px;
		line-height: 23px;
		margin-bottom: 12px;

		@media (max-width: 950px) {
			font-size: 16px;
		}
	}
	.footer {
		font-weight: 700;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: #64748b;
		margin-top: auto;

		@media (max-width: 520px) {
			font-size: 10px;
		}
	}

	:hover .title,
	:focus .title {
		text-decoration: underline;
	}
`;
