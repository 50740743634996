import { Container } from './styles';

const SomethingWentWrong = () => {
	return (
		<Container>
			<h1>Something went wrong while loading this page</h1>
			<br />
			<p>Please refresh the page</p>
		</Container>
	);
};
export default SomethingWentWrong;
