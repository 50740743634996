import { Link } from 'react-router-dom';
import { Approutes } from '../../../constants';
import { Container } from './styles';
import { getDateTime } from '../../../utilities';

const Post = ({ post }) => {
	return (
		<Container>
			<Link to={Approutes.blogs.use_single(post?.slug || '')}>
				<img src={post.thumbnail} alt="" />
				<div className="type--author">
					<div className="caption-con">
						<span className="caption default">{post?.category}</span>
					</div>
				</div>
				<div className="title">{post?.title}</div>
				<p>{post?.snippet}</p>
				<div className="footer">{getDateTime(post?.createdAt)}</div>

				<button className="learn-more">Learn More</button>
			</Link>
		</Container>
	);
};

export default Post;
