export const Approutes = {
	home: '/',
	about_us: '/about-us',
	playground: '/playground',
	blogs: {
		all: '/blogs',
		single: '/blogs/:slug',
		use_single: (id) => `/blogs/${id}`,
	},
	docs: {
		index: '/docs',
		terms_of_use: '/docs/terms-of-use',
		privacy_policy: '/docs/privacy-policy',
		code_of_conduct: '/docs/code-of-conduct',
		core_values: '/docs/core-values',
		refund_policy: '/docs/refund-policy',
		community_guidelines: '/docs/community-guidelines',
	},
};
