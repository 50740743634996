import axios from 'axios';

const LIVE_BASE_URL = 'https://app-api.descinder.com/api/v1';

const BASE_URL = 'https://web-api.descinder.com/';

export const olderAxios = axios.create({
	baseURL: BASE_URL,
	headers: { 'Content-Type': 'application/json' },
});

export default axios.create({
	baseURL: LIVE_BASE_URL,
	headers: { 'Content-Type': 'application/json' },
});
