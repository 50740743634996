import { Container } from './styles';
import { useState, useRef } from 'react';
import Post from './Post';
import Pagination from './Pagination';
import { useGetBlogs } from '../../hooks';
import { Loader } from '../../ui';

const Insights = () => {
	const [page, setPage] = useState(1);

	const pageSize = useRef(10);
	const { data: blogs, isLoading } = useGetBlogs({ page, page_size: pageSize.current, search: '' });

	return (
		<Container className="container padded">
			<div className="header">
				<h2>Explore Insights</h2>
				<p>Here we share our views on the latest updates in technology and business.</p>
			</div>

			{isLoading ? (
				<center>
					<br />
					<br />
					<br />
					<Loader />
				</center>
			) : (
				<div className="posts-con">
					<div className="posts">
						{blogs?.results?.map((post) => (
							<Post post={post} key={post.title} />
						))}
					</div>

					<Pagination page={page} setPage={setPage} maxPages={Math.ceil(blogs?.total / pageSize.current)} disabled={isLoading} />
				</div>
			)}

			{/* <Newsletter /> */}
		</Container>
	);
};

export default Insights;
