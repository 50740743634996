import styled from 'styled-components';

export const Container = styled.div.attrs({ tabIndex: 0 })`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	outline: none;
	cursor: pointer;

	> * > img {
		height: 350px;
		width: 100%;
		object-fit: cover;
		border-radius: 12px;
	}
	.type--author {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 16px 0 12px;
	}
	.author {
		font-family: 'Sora', sans-serif;
		font-weight: 700;
		font-size: 14px;
		line-height: 17px;
		text-transform: uppercase;

		@media (max-width: 900px) {
			font-size: 12px;
		}
		@media (max-width: 520px) {
			font-size: 10px;
		}
	}
	.caption-con {
		display: flex;
		align-items: center;
		gap: 12px;
	}
	.caption {
		font-weight: 700;
		font-size: 10px;
		letter-spacing: 0.08em;
		text-transform: capitalize;
		width: fit-content;
		padding: 2px 8px;
		border-radius: 18px;
		border: 1px solid #1212121c;

		@media (max-width: 520px) {
			font-size: 8px;
		}
	}
	.title {
		font-weight: 500;
		font-size: 24px;
		line-height: 120%;
		margin-bottom: 10px;

		@media (max-width: 950px) {
			font-size: 20px;
		}
	}
	p {
		margin-bottom: 15px;
	}
	.footer {
		font-weight: 700;
		font-size: 12px;
		line-height: 15px;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: #64748b;
		margin-top: auto;

		@media (max-width: 520px) {
			font-size: 10px;
		}
	}

	:hover .title,
	:focus .title,
	:hover .learn-more,
	:focus .learn-more {
		text-decoration: underline;
	}

	.learn-more {
		color: #4060ea;
		padding: 3px 8px;
		border-radius: 18px;
		border: 1px solid #1212121c;
		background-color: transparent;
		display: grid;
		place-items: center;
	}
`;
